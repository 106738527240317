import i18n from '@/libs/i18n'
import { availableCountries } from '@/utils/location/phoneCodeCountries'
import { localize } from 'vee-validate'
import store from '@/store'

export const pointTypes = {
  data() {
    return {
      pointTypes: [
        {
          title: 'Target',
          slug: 'target',
          icon: 'CrosshairIcon',
          tooltip: 'somedesc',

        },
        {
          title: 'Intermediate',
          slug: 'intermediate',
          icon: 'MapPinIcon',
          tooltip: 'somedesc',
        },
        {
          title: 'Turn',
          slug: 'turn',
          icon: 'CompassIcon',
          tooltip: 'somedesc',
        },
      ],
    }
  },
}
export const servicesTypes = {
  data() {
    return {
      servicesTypes: [
      ],
    }
  },
}

export const serviceOptions = {
  categories: [
    {
      slug: 'home_master',
      title: 'Home master',
      icon: '',
      point_purposes: [
        {
          slug: 'plumber',
          title: 'Plumber',
        },
        {
          slug: 'electrician',
          title: 'Electrician',
        },
        {
          slug: 'repair_of_windows_and_doors',
          title: 'Repair of windows & doors',
        },
        {
          slug: 'installation_of_household_appliances',
          title: 'Installation of Household Appliances',
        },
        {
          slug: 'other',
          title: 'Other',
        },
      ],
    },
    {
      slug: 'vehicle_repair',
      icon: '',
      title: 'Vehicle repair',
      point_purposes: [
        {
          slug: 'inspection',
          title: 'Inspection',
        },
        {
          slug: 'computer_diagnostics',
          title: 'Computer diagnostics',
        },
        {
          slug: 'engine',
          title: 'Engine',
        },
        {
          slug: 'body_work',
          title: 'Body work',
        },
        {
          slug: 'chassis',
          title: 'Chassis',
        },
        {
          slug: 'electronics',
          title: 'Electronics',
        },
        {
          slug: 'transmission',
          title: 'Transmission',
        },
        {
          slug: 'exhaust_system',
          title: 'Exhaust system',
        },
        {
          slug: 'painting_works',
          title: 'Painting works',
        },
        {
          slug: 'tire_fitting',
          title: 'Tire fitting',
        },
        {
          slug: 'repair_and_replacement_of_glass',
          title: 'Repair and replacement of glass',
        },
        {
          slug: 'installation_of_gas_cylinder_equipment',
          title: 'Installation of gas cylinder equipment',
        },
        {
          slug: 'air_conditioners',
          title: 'Air conditioners',
        },
        {
          slug: 'salon_care',
          title: 'Salon care',
        },
        {
          slug: 'motorcycle_repair',
          title: 'Motorcycle repair',
        },
        {
          slug: 'bicycle_repair',
          title: 'Bicycle repair',
        },
        {
          slug: 'repair_of_scooters',
          title: 'Repair of scooters',
        },
        {
          slug: 'other',
          title: 'Other',
        },
      ],
    },
    {
      slug: 'construction_works',
      title: 'Construction works',
      icon: '',
      point_purposes: [
        {
          slug: 'brick_laying',
          title: 'Brick laying',
        },
        {
          slug: 'laying_tiles',
          title: 'Laying tiles',
        },
        {
          slug: 'plasterboard_works',
          title: 'Plasterboard works',
        },
        {
          slug: 'woodwork',
          title: 'Woodwork',
        },
        {
          slug: 'metal_work',
          title: 'Metal work',
        },
        {
          slug: 'roofing_works',
          title: 'Roofing works',
        },
        {
          slug: 'concrete_works',
          title: 'Concrete works',
        },
        {
          slug: 'boring',
          title: 'Boring',
        },
        {
          slug: 'welding_works',
          title: 'Welding works',
        },
        {
          slug: 'earthworks',
          title: 'Earthworks',
        },
        {
          slug: 'construction_of_houses',
          title: 'Construction of Houses',
        },
        {
          slug: 'construction_of_small_structures',
          title: 'Construction of small structures',
        },
        {
          slug: 'fences_and_gates_installation',
          title: 'Fences and gates installation',
        },
        {
          slug: 'industrial_mountaineering',
          title: 'Industrial mountaineering',
        },
        {
          slug: 'sorting_of_debris',
          title: 'Sorting of debris',
        },
        {
          slug: 'other',
          title: 'Other',
        },
      ],
    },
    {
      slug: 'equipment_repair',
      title: 'Equipment repair',
      icon: '',
      point_purposes: [
        {
          slug: 'air_conditioners',
          title: 'Air Conditioners',
        },
        {
          slug: 'refrigerators_and_freezers',
          title: 'Refrigerators & Freezers',
        },
        {
          slug: 'washing_and_drying_machines',
          title: 'Washing & Drying machines',
        },
        {
          slug: 'other',
          title: 'Other',
        },
      ],
    },
    {
      slug: 'transport_services',
      icon: '',
      title: 'Transport services',
      point_purposes: [
        {
          slug: 'freight_transportation',
          title: 'Freight transportation',
        },
        {
          slug: 'services_of_porters',
          title: 'Services of porters',
        },
        {
          slug: 'removal_of_construction_waste',
          title: 'Removal of construction waste',
        },
        {
          slug: 'transportation_of_furniture_and_equipment',
          title: 'Transportation of furniture and equipment',
        },
        {
          slug: 'intercity_transportation',
          title: 'Intercity transportation',
        },
        {
          slug: 'international_transportation',
          title: 'International transportation',
        },
        {
          slug: 'other',
          title: 'Other',
        },
      ],
    },
    {
      slug: 'courier_services',
      icon: '',
      title: 'Courier services',
      point_purposes: [
        {
          slug: 'delivery_of_products',
          title: 'Delivery of products',
        },
        {
          slug: 'delivery_of_medicines',
          title: 'Delivery of medicines',
        },
        {
          slug: 'delivery_of_goods_from_the_online_store',
          title: 'Delivery of goods from the online store',
        },
        {
          slug: 'delivery_of_documents',
          title: 'Delivery of documents',
        },
        {
          slug: 'delivery_of_gifts',
          title: 'Delivery of gifts',
        },
        {
          slug: 'send_by_train_or_bus',
          title: 'Send by train or bus',
        },
        {
          slug: 'pick_up_and_send_by_mail',
          title: 'Pick up and send by mail',
        },
        {
          slug: 'other',
          title: 'Other',
        },

      ],
    },
    {
      slug: 'cleaning_services',
      icon: '',
      title: 'Cleaning services',
      point_purposes: [
        {
          slug: 'cleaning_of_apartments_and_houses',
          title: 'Cleaning Apartments & Houses',
        },
        {
          slug: 'cleaning_of_industrial_premises',
          title: 'Cleaning of industrial premises',
        },
        {
          slug: 'cleaning_of_surrounding_areas',
          title: 'Cleaning the surrounding areas',
        },
        {
          slug: 'disinfection_and_dry_cleaning_of_premises',
          title: 'Disinfection and dry cleaning of premises',
        },
        {
          slug: 'other',
          title: 'Other',
        },
      ],
    },
  ],
  closeReasons: {
    request_completed: 'Request completed',
    request_non_actual: 'Request non actual',
    another: 'Another reason',
  },
  getCloseReasonTitle(val) {
    return serviceOptions.closeReasons[val]
  },
  getServiceCloseReasons() {
    const { request_completed, ...obj } = serviceOptions.closeReasons
    return obj
  },
  getServiceCompleteReason() {
    const {  request_non_actual, another, ...obj } = serviceOptions.closeReasons
    return obj
  },

  getPurposeTitle(slug) {
    return slug !== undefined ? i18n.t(`point.purpose.${slug}`) : ''
  },
  getTypeTitle(slug) {
    return slug !== undefined ? i18n.t(`point.type.${slug}`) : ''
  },
  getTranslatedPointTypes(obj) {
    if (obj) {
      const newObj = JSON.parse(JSON.stringify(obj))
      newObj.map(option => {
        option.title = i18n.t(`point.type.${option.slug}`)
        return option
      })
      return newObj.sort((a, b) => a.title.localeCompare(b.title))
    }
    return obj
  },
  getTranslatedPointPurposes(obj) {
    if (obj) {
      const newObj = JSON.parse(JSON.stringify(obj))
      newObj.map(option => {
        option.title = i18n.t(`point.purpose.${option.slug}`)
        return option
      })
      return newObj.sort((a, b) => a.title.localeCompare(b.title))
      // return newObj
    }
    return obj
  },

}
export const locales = () => {
  const localesList = [
    // {
    //   locale: 'fr',
    //   img: require('@/assets/images/flags/fr.png'),
    //   name: 'French',
    // },
    {
      locale: 'uk',
      img: require('@/assets/images/flags/uk.png'),
      name: 'Укр',
      label: 'uk',
    },
    {
      locale: 'en',
      img: require('@/assets/images/flags/en-gb.png'),
      name: 'Eng',
      label: 'en',
    },
    {
      locale: 'es',
      img: require('@/assets/images/flags/es.png'),
      name: 'Esp',
      label: 'es',
    },
    {
      locale: 'de',
      img: require('@/assets/images/flags/de.png'),
      name: 'Deu',
      label: 'de',
    },
    {
      locale: 'ru',
      img: require('@/assets/images/flags/ru.png'),
      name: 'Рус',
      label: 'ru',
    },

  ]
  const countriesList = availableCountries

  const getLocale = (locale)=>localesList.find(el=>el.locale===locale)

  const getLang = (lang)=>{
    const locale = getLocale(lang)
    return locale?locale.name:getLang(i18n.locale)
  }
  const setLocale = (locale)=>{
    localStorage.setItem('locale', locale)
    localize(locale)
    i18n.locale = locale
    return locale
  }
  return {
    localesList,
    countriesList,
    getLang,
    setLocale,
  }
}
export const messengers = () => {
  const messengersList = [
    {
      icon: require('@/assets/images/icons/viber-48.png'),
      name: 'Viber',
      slug: 'viber',
      link:'viber://pa?chatURI='
    },
    {
      icon: require('@/assets/images/icons/whatsapp-48.png'),
      name: 'WhatsApp',
      slug: 'whatsapp',
      link:'https://wa.me/'
    },
    {
      icon: require('@/assets/images/icons/telegram-48.png'),
      name: 'Telegram',
      slug: 'telegram',
      link:'https://t.me/'
    },

  ]

  return {
    messengersList,
  }
}
export const currency = () => {
  const currencyList = [
    {
      currency: 'UAH',
      symbol: '₴',
      countryCodes: ['UA'],
      label: 'UAH',
    },
    {
      currency: 'USD',
      symbol: '$',
      countryCodes: ['US'],
      label: 'USD',
    },
    {
      currency: 'EUR',
      symbol: '€',
      countryCodes: ['GB', 'ES'],
      label: 'EUR',
    },

  ]

  const getCurrency = () => {
    const currencyCode = localStorage.getItem('currencyCode')
    return (currencyCode && currencyList.find(el => el.currency === currencyCode)) ? currencyCode : 'UAH'
  }

  const getCountryCurrency = countryCode => {
    let code = countryCode
    if (!code) {
      code = store.state.user.geoData.country_code|| 'UA'
    }
    return currencyList.find(item => item.countryCodes.includes(code)) || 'UAH'
  }

  const getCurrencySymbol = currencyCode => {
    let code = currencyCode
    if (!code) {
      const countryCurrency = getCountryCurrency()
      code = countryCurrency.currency
    }
    const currencyObj = currencyList.find(item => item.currency === code)
    return currencyObj ? currencyObj.symbol : ''
  }
  const getCurrencies = () => currencyList.map(el => el.currency)

  return {
    getCountryCurrency,
    getCurrencySymbol,
    getCurrencies,
    getCurrency,
    currencyList,
  }
}
export const event = {
  postStatuses: [
    {
      title: 'published',
      slug: 'published',
      icon: 'EyeIcon',
      class: 'success',
    },
    {
      title: 'archived',
      slug: 'archived',
      icon: 'ArchiveIcon',
      class: 'dark',
    },
  ],
  declineReasons: {
    ...{ another_proposal_accepted: 'Another proposal accepted' }, ...serviceOptions.closeReasons,
  },
  withdrawReasons: {
    busy: 'Too busy',
    another: 'Another reason',
  },
  getDeclineReasonTitle(val) {
    return i18n.t(event.declineReasons[val])
  },
  getWithdrawReasonTitle(val) {
    return i18n.t(event.withdrawReasons[val])
  },
  getWithdrawReasons() {
    const obj = { ...event.withdrawReasons }
    Object.keys(obj).forEach(key => {
      obj[key] = i18n.t(obj[key])
    })
    return obj
  },
  getDeclineReasons() {
    const {  request_completed, another_proposal_accepted, ...obj } = event.declineReasons
    Object.keys(obj).forEach(key => {
      obj[key] = i18n.t(obj[key])
    })
    return obj
  },
}

export const postStatuses = {
  data() {
    return {
      postStatuses: [
        {
          title: 'draft',
          slug: 'draft',
          icon: 'EyeOffIcon',
          icon_visibility: 'EyeOffIcon',
          class: 'secondary',
        },
        {
          title: 'published',
          slug: 'published',
          icon: 'BookOpenIcon',
          icon_visibility: 'EyeIcon',
          class: 'success',
        },
        {
          title: 'archived',
          slug: 'archived',
          icon: 'ArchiveIcon',
          icon_visibility: 'EyeOffIcon',
          class: 'secondary',
        },
        {
          title: 'closed',
          slug: 'closed',
          icon: 'BookIcon',
          icon_visibility: 'EyeOffIcon',
          class: 'secondary',
        },
        {
          title: 'incomplete',
          slug: 'incomplete',
          icon: 'AlertTriangleIcon',
          icon_visibility: 'EyeOffIcon',
        },
      ],
    }
  },
}
export const pointOwnerOptions = [
  {
    title: 'Own',
    managed: undefined,
    slug: 'own',
    icon: 'UserIcon',
    class: 'light-secondary',
  },
  {
    title: 'On Behalf',
    managed: false,
    slug: 'onBehalf',
    icon: 'UsersIcon',
    class: 'light-secondary',
  },
  {
    title: 'On Behalf. Managed',
    managed: true,
    slug: 'onBehalfManaged',
    icon: 'UsersIcon',
    class: 'light-primary',
  },
]
export const _ = null
