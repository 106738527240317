import axios from '@axios'

export default {
  namespaced: true,
  state: {
    user: {},
    serviceProvider: {},
    userManaged: {},
    geoData:{
      address:{},
      lat:'',
      lon: ''
    },
  },
  getters: {
    user: state => state.user,
    serviceProvider: state => state.serviceProvider,
    userManaged: state => state.userManaged,
    geoData: state => state.geoData,
  },
  mutations: {

    setUser(state, user) {
      state.user = user
    },
    setServiceProvider(state, user) {
      state.serviceProvider = user
    },
    setUserManaged(state, user) {
      state.userManaged = user
    },
    unsetUserManaged(state){
      state.userManaged = {}
    },
    updateUser(state, user) {
      state.user = { ...state.user, ...user }
    },
    setGeoData(state, geoData){
      state.geoData = geoData
    },
    setGeoDataLat(state, lat){
      state.geoData.lat = lat
    },
    setGeoDataLon(state, lon){
      state.geoData.lon = lon
    },
    updateGeoData(state,geoData){
      state.geoData = {...state.geoData, ...geoData}
    }
  },
  actions: {
    setUserManaged(ctx, user) {
      ctx.commit('setUserManaged', user)
    },
    async fetchServiceProviderPublic(ctx, { id }) {
      const response = await axios.get(`/public/service-providers/${id}?include=service_offers.point_type,service_offers.point_purposes,service_offers.media_files,languages`)
      const successStatuses = [200, 201]
      if (successStatuses.includes(response.status)) {
        ctx.commit('setServiceProvider', response.data.data)
      }
      return response
    },
    async fetchServiceProvider(ctx, { id }) {
      const response = await axios.get(`/service-providers/${id}?include=service_offers.point_type,service_offers.point_purposes,service_offers.media_files,languages`)
      const successStatuses = [200, 201]
      if (successStatuses.includes(response.status)) {
        ctx.commit('setServiceProvider', response.data.data)
      }
      return response
    },

    async fetchServiceProviders(ctx, queryParams) {
      const response = await axios.get('/service-providers?include=languages,service_offers', {
        params: queryParams,
      }).catch(error => console.log(error))
      return response
    },

    async fetchServiceProviderPhones(ctx, { id }) {
      return await axios.get(`/service-providers/${id}/phones`)
    },

    async fetchUserPhones(ctx, { id }) {
      return await axios.get(`/users/${id}/phones`)
    },

    async fetchReferralPhones(ctx, { id }) {
      return await axios.get(`/referrals/${id}/phones`)
    },

    async createUserManaged(ctx, account) {
      const response = await axios.post('users/managed', account)
      const successStatuses = [200, 201]
      if (successStatuses.includes(response.status)) {
        response.statusText = 'added'
      } else {
        response.statusText = 'error'
      }
      return response
    },
    async fetchGeoData(ctx) {
      const response = await axios.get('/ip-locator').catch(error => {
        console.log(error.response)
      })
      const successStatuses = [200, 201]
      if (successStatuses.includes(response.status)) {
        const {data}=response.data
        if(!data) return false
        const geoData = data
        delete geoData.status
        ctx.commit('setGeoData', geoData)
      }
      return response
    },

  },
}
