import axios from '@/libs/axios'

export default {
  namespaced: true,
  state: {
    fcmToken: null,
    notifications: [],
    notificationsUnreadQty:0,
    notificationsEnabled:false
  },
  getters: {
    fcmToken: state => state.fcmToken,
    notifications: state => state.notifications,
    notificationsUnreadQty: state => state.notificationsUnreadQty,
    notificationsEnabled: state => state.notificationsEnabled,
  },
  mutations: {
    SET_FCM_TOKEN(state, val) {
      state.fcmToken = val
    },
    SET_NOTIFICATIONS(state, notifications) {
      state.notifications = notifications
    },
    SET_NOTIFICATIONS_UNREAD_QTY(state, qty) {
      state.notificationsUnreadQty = qty
    },
    DECREASE_NOTIFICATIONS_UNREAD_QTY(state, qty){
      state.notificationsUnreadQty -= qty
    },
    SET_NOTIFICATIONS_ENABLED(state, enabled){
      state.notificationsEnabled = enabled
    },
    DELETE_NOTIFICATION_DATA(state){
      state.notifications = [],
      state.notificationsUnreadQty = 0,
      state.notificationsEnabled = false
    }

  },
  actions: {
    async fetchNotifications(ctx, queryParams) {
      const response = await axios.get('/notifications', {
        params: queryParams,
      }).catch(error => {
        if (error.response.status === 404) {
          return error.response
        }
        console.log(error)
      })
      const successStatuses = [200, 201]
      if (successStatuses.includes(response.status)) {
        if(queryParams.unread==='unread'){
          ctx.commit('SET_NOTIFICATIONS_UNREAD_QTY', response.data.meta.total)
        }
        ctx.commit('SET_NOTIFICATIONS', response.data.data)
      }
      return response
    },
    async readNotification(ctx, id) {
      return axios.post(`/notifications/${id}/read`).catch(error => {
        if (error.response.status === 404) {
          return error.response
        }
        console.log(error)
      })
    },
    async readAllNotifications(ctx) {
      return axios.post('/notifications/read-all').catch(error => {
        if (error.response.status === 404) {
          return error.response
        }
        console.log(error)
      })
    },
    async addFcmToken(ctx, data) {
      return axios.post('/notifications/fcm-tokens', data).then(() => {
      }).catch(error => console.log(error))
    },
    async replaceFcmToken(ctx, data) {
      return axios.post('/notifications/fcm-tokens/replace', {
        old_token: data.oldToken,
        new_token: data.newToken,
      }).catch(error => console.log(error))
    },
  },
}
