import axios from '@axios'

export default {
  namespaced: true,
  state: {
    referralCode: null,
    referralCodes: [],
    referrals: [],
  },
  getters: {
    referralCode: state => state.referralCode,
    referralCodes: state => state.referralCodes,
    referrals: state => state.referrals,
  },
  mutations: {
    setReferralCodes(state, referralCodes) {
      state.referralCodes = referralCodes
    },
    setReferrals(state, referrals) {
      state.referrals = referrals
    },
    addReferralCode(state, referralCode) {
      state.referralCode = referralCode
      state.referralCodes.unshift(referralCode)
    },
  },
  actions: {
    async createReferralCode(ctx, { code, description }) {
      return new Promise((resolve, reject) => {
        axios
          .post('/referral-codes', { code, description })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    async getReferralCodes(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .get('/referral-codes', { params })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    async getReferrals(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .get('/referrals', { params })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    async fetchReferral(ctx, { id }) {
      const response = await axios.get(`/referrals/${id}?include=service_offers.point_purposes,service_offers.point_type,subscriptions.abilities.limits`)
        .catch(error => {
          if (error.response.status === 404) {
            return error.response
          }
          console.log(error)
        })

      return response
    },
  },
}
