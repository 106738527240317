export default [
  // {
  //   path: '/apps/calendar',
  //   name: 'apps-calendar',
  //   component: () => import('@/views/apps/calendar/Calendar.vue'),
  // },

  // *===============================================---*
  // *--------- EMAIL & IT'S FILTERS N LABELS -------------------------------*
  // *===============================================---*
  // {
  //   path: '/apps/email',
  //   name: 'apps-email',
  //   component: () => import('@/views/apps/email/Email.vue'),
  //   meta: {
  //     contentRenderer: 'sidebar-left',
  //     contentClass: 'email-application',
  //   },
  // },
  // {
  //   path: '/apps/email/:folder',
  //   name: 'apps-email-folder',
  //   component: () => import('@/views/apps/email/Email.vue'),
  //   meta: {
  //     contentRenderer: 'sidebar-left',
  //     contentClass: 'email-application',
  //     navActiveLink: 'apps-email',
  //   },
  //   beforeEnter(to, _, next) {
  //     if (['sent', 'draft', 'starred', 'spam', 'trash'].includes(to.params.folder)) next()
  //     else next({ name: 'error-404' })
  //   },
  // },
  // {
  //   path: '/apps/email/label/:label',
  //   name: 'apps-email-label',
  //   component: () => import('@/views/apps/email/Email.vue'),
  //   meta: {
  //     contentRenderer: 'sidebar-left',
  //     contentClass: 'email-application',
  //     navActiveLink: 'apps-email',
  //   },
  //   beforeEnter(to, _, next) {
  //     if (['personal', 'company', 'important', 'private'].includes(to.params.label)) next()
  //     else next({ name: 'error-404' })
  //   },
  // },

  // *===============================================---*
  // *--------- TODO & IT'S FILTERS N TAGS ---------------------------------------*
  // *===============================================---*
  // {
  //   path: '/apps/todo',
  //   name: 'apps-todo',
  //   component: () => import('@/views/apps/todo/Todo.vue'),
  //   meta: {
  //     contentRenderer: 'sidebar-left',
  //     contentClass: 'todo-application',
  //   },
  // },
  // {
  //   path: '/apps/todo/:filter',
  //   name: 'apps-todo-filter',
  //   component: () => import('@/views/apps/todo/Todo.vue'),
  //   meta: {
  //     contentRenderer: 'sidebar-left',
  //     contentClass: 'todo-application',
  //     navActiveLink: 'apps-todo',
  //   },
  //   beforeEnter(to, _, next) {
  //     if (['important', 'completed', 'deleted'].includes(to.params.filter)) next()
  //     else next({ name: 'error-404' })
  //   },
  // },
  // {
  //   path: '/apps/todo/tag/:tag',
  //   name: 'apps-todo-tag',
  //   component: () => import('@/views/apps/todo/Todo.vue'),
  //   meta: {
  //     contentRenderer: 'sidebar-left',
  //     contentClass: 'todo-application',
  //     navActiveLink: 'apps-todo',
  //   },
  //   beforeEnter(to, _, next) {
  //     if (['team', 'low', 'medium', 'high', 'update'].includes(to.params.tag)) next()
  //     else next({ name: 'error-404' })
  //   },
  // },

  // *===============================================---*
  // *--------- CHAT  ---------------------------------------*
  // *===============================================---*
  // {
  //   path: '/apps/chat',
  //   name: 'apps-chat',
  //   component: () => import('@/views/apps/chat/Chat.vue'),
  //   meta: {
  //     contentRenderer: 'sidebar-left',
  //     contentClass: 'chat-application',
  //   },
  // },

  // *===============================================---*
  // *--------- ECOMMERCE  ---------------------------------------*
  // *===============================================---*
  // {
  //   path: '/apps/e-commerce/shop',
  //   name: 'apps-e-commerce-shop',
  //   component: () => import('@/views/apps/e-commerce/e-commerce-shop/ECommerceShop.vue'),
  //   meta: {
  //     contentRenderer: 'sidebar-left-detached',
  //     contentClass: 'ecommerce-application',
  //     pageTitle: 'Shop',
  //     breadcrumb: [
  //       {
  //         text: 'ECommerce',
  //       },
  //       {
  //         text: 'Shop',
  //         active: true,
  //       },
  //     ],
  //   },
  // },
  // {
  //   path: '/apps/e-commerce/wishlist',
  //   name: 'apps-e-commerce-wishlist',
  //   component: () => import('@/views/apps/e-commerce/e-commerce-wishlist/ECommerceWishlist.vue'),
  //   meta: {
  //     pageTitle: 'Wishlist',
  //     contentClass: 'ecommerce-application',
  //     breadcrumb: [
  //       {
  //         text: 'ECommerce',
  //       },
  //       {
  //         text: 'Wishlist',
  //         active: true,
  //       },
  //     ],
  //   },
  // },
  // {
  //   path: '/apps/e-commerce/checkout',
  //   name: 'apps-e-commerce-checkout',
  //   component: () => import('@/views/apps/e-commerce/e-commerce-checkout/ECommerceCheckout.vue'),
  //   meta: {
  //     pageTitle: 'Checkout',
  //     contentClass: 'ecommerce-application',
  //     breadcrumb: [
  //       {
  //         text: 'ECommerce',
  //       },
  //       {
  //         text: 'Checkout',
  //         active: true,
  //       },
  //     ],
  //   },
  // },
  // {
  //   path: '/apps/e-commerce/:slug',
  //   name: 'apps-e-commerce-product-details',
  //   component: () => import('@/views/apps/e-commerce/e-commerce-product-details/ECommerceProductDetails.vue'),
  //   meta: {
  //     pageTitle: 'Product Details',
  //     contentClass: 'ecommerce-application',
  //     breadcrumb: [
  //       {
  //         text: 'ECommerce',
  //       },
  //       {
  //         text: 'Shop',
  //         active: true,
  //       },
  //       {
  //         text: 'Product Details',
  //         active: true,
  //       },
  //     ],
  //   },
  // },

  // *===============================================---*
  // *--------- USER ---- ---------------------------------------*
  // *===============================================---*
  // {
  //   path: '/service/providers/list',
  //   name: 'apps-user-service-providers-list',
  //   component: () => import('@/views/apps/user/service-providers-list/ServiceProvidersList.vue'),
  //   meta: {
  //     resource: 'Auth',
  //     action: 'read',
  //     contentRenderer: 'sidebar-left-detached',
  //     contentClass: 'ecommerce-application',
  //     pageTitle: 'f',
  //     breadcrumb: [
  //       {
  //         text: 'List',
  //         active: true,
  //       },
  //     ],
  //   },
  // },
  // {
  //   path: '/apps/users/list',
  //   name: 'apps-users-list',
  //   component: () => import('@/views/apps/user/users-list/UsersList.vue'),
  // },
  // {
  //   path: '/apps/users/view/:id',
  //   name: 'apps-users-view',
  //   component: () => import('@/views/apps/user/users-view/UsersView.vue'),
  // },
  // {
  //   path: '/apps/users/edit/:id',
  //   name: 'apps-users-edit',
  //   component: () => import('@/views/apps/user/users-edit/UsersEdit.vue'),
  // },

  // *===============================================---*
  // *--------- POINT ---- ---------------------------------------*
  // *===============================================---*
  // {
  //   path: '/apps/points/list-table',
  //   name: 'apps-points-list-table',
  //   component: () => import('@/views/apps/point/points-list-table/PointsList.vue'),
  //   meta: {
  //     resource: 'Point',
  //     action: 'read',
  //   },
  // },
  // {
  //   path: '/apps/points/list',
  //   name: 'apps-points-list',
  //   component: () => import('@/views/apps/point/points-list/PointsList.vue'),
  //   meta: {
  //     resource: 'Point',
  //     action: 'read',
  //     contentRenderer: 'sidebar-left-detached',
  //     contentClass: 'ecommerce-application',
  //     pageTitle: 'Points',
  //     breadcrumb: [
  //       {
  //         text: 'List',
  //         active: true,
  //       },
  //     ],
  //   },
  // },
  // {
  //   path: '/apps/points/view/:id',
  //   name: 'apps-points-view',
  //   component: () => import('@/views/apps/point/points-view/PointsView.vue'),
  // },
  // {
  //   path: '/apps/points/edit/:id',
  //   name: 'apps-points-edit',
  //   component: () => import('@/views/apps/point/points-edit/PointsEdit.vue'),
  //   meta: {
  //     resource: 'Point',
  //     action: 'update',
  //     pageTitle: 'Edit Point',
  //     breadcrumb: [
  //       {
  //         text: 'Points',
  //       },
  //       {
  //         text: 'Edit Point',
  //         active: true,
  //       },
  //     ],
  //   },
  // },
  // {
  //   path: '/apps/points/add',
  //   name: 'apps-points-add',
  //   component: () => import('@/views/apps/point/points-edit/PointsEdit.vue'),
  //   meta: {
  //     resource: 'Point',
  //     action: 'create',
  //     pageTitle: 'Add New Point',
  //     breadcrumb: [
  //       {
  //         text: 'Points',
  //       },
  //       {
  //         text: 'Add New Point',
  //         active: true,
  //       },
  //     ],
  //   },
  // },
  // {
  //   path: '/apps/points/:id',
  //   name: 'apps-points-details',
  //   component: () => import('@/views/apps/point/points-details/PointDetails.vue'),
  //   meta: {
  //     resource: 'Point',
  //     action: 'read',
  //     pageTitle: 'Point Details',
  //     contentClass: 'ecommerce-application',
  //     breadcrumb: [
  //       {
  //         text: 'Points',
  //       },
  //       {
  //         text: 'Point Details',
  //         active: true,
  //       },
  //     ],
  //   },
  // },
  // *===============================================---*
  // *-------------- REFERRAL --------------------------*
  // *===============================================---*
  {
    path: '/r',
    name: 'app-ref',
    component: () => import('@/views/apps/referral/ReferralPage.vue'),
    meta: {
      resource: 'Auth',
    },
  },
  {
    path: '/q',
    name: 'app-service-query',
    component: () => import('@/views/apps/service/ServiceQueryPage.vue'),
    meta: {
      resource: 'Auth',
    },
  },
  // *===============================================---*
  // *--------- OFFER SERVICE --------------------------*
  // *===============================================---*
  {
    path: '/service/offer/list',
    name: 'apps-service-offer-list',
    component: () => import('@/views/apps/service/offer/list/List.vue'),
    meta: {
      resource: 'Auth',
      action: 'read',
      contentClass: 'ecommerce-application',
      pageTitle: 'Offers',
      breadcrumb: [
        {
          text: 'List',
          active: true,
        },
      ],
    },
  },
  {
    path: '/service/my-offers',
    name: 'apps-service-my-offers',
    component: () => import('@/views/apps/service/offer/list/OfferList.vue'),
    meta: {
      resource: 'ServiceOffer',
      action: 'read',
      contentRenderer: 'sidebar-left-detached',
      contentClass: 'ecommerce-application',
      pageTitle: 'My Offers',
      breadcrumb: [
        {
          text: 'List',
          active: true,
        },
      ],
    },
  },
  // {
  //   path: '/service/view/:id',
  //   name: 'apps-service-view',
  //   component: () => import('@/views/apps/point/service-view/ServiceView.vue'),
  // },
  {
    path: '/service/offer/edit/:id',
    name: 'apps-service-offer-edit',
    component: () => import('@/views/apps/service/offer/edit/OfferEdit.vue'),
    meta: {
      resource: 'ServiceOffer',
      action: 'update',
      pageTitle: 'Offer',
      breadcrumb: [
        {
          text: 'My Offers',
          to: { name: 'apps-service-my-offers' },
        },
        {
          text: 'Editing',
          active: true,
        },
      ],
    },
  },
  {
    path: '/service/offer/add-wizard',
    name: 'apps-service-offer-add-wizard',
    component: () => import('@/views/apps/service/offer/edit/OfferEditWizard.vue'),
    meta: {
      resource: 'Auth',
      action: 'read',
      pageTitle: 'Add',
      breadcrumb: [

        {
          text: 'Offer services',
          active: true,
        },
      ],
    },
  },
  // {
  //   path: '/service/offer/add',
  //   name: 'apps-service-offer-add',
  //   component: () => import('@/views/apps/service/offer/edit/OfferEdit.vue'),
  //   meta: {
  //     resource: 'ServiceOffer',
  //     action: 'create',
  //     pageTitle: 'Offer',
  //     breadcrumb: [
  //       {
  //         text: 'My Offers',
  //         to: { name: 'apps-service-my-offers' },
  //       },
  //       {
  //         text: 'Add',
  //         active: true,
  //       },
  //     ],
  //   },
  // },
  {
    path: '/service/offer/:id',
    name: 'apps-service-offer-info',
    component: () => import('@/views/apps/service/offer/OfferInfo.vue'),
    meta: {
      resource: 'ServiceOffer',
      action: 'read',
      pageTitle: 'Offer',
      contentClass: 'ecommerce-application',
      breadcrumb: [
        {
          text: 'My Offers',
          to: { name: 'apps-service-my-offers' },
        },
        {
          text: 'Details',
          active: true,
        },
      ],
    },
  },
  // *===============================================---*
  // *--------- LOCATIONS --------------------------*
  // *===============================================---*
  {
    path: '/location/info/:id',
    name: 'apps-location-info',
    component: () => import('@/views/apps/location/LocationInfo.vue'),
    meta: {
      resource: 'ServiceRequest',
      action: 'read',
      pageTitle: 'location.location',
      contentClass: 'ecommerce-application',
      breadcrumb: [
        {
          text: 'location.my_locations',
          to: { name: 'apps-location-list' },
        },
        {
          text: 'Info',
          active: true,
        },
      ],
    },
  },
  {
    path: '/location/add',
    name: 'apps-location-add',
    component: () => import('@/views/apps/location/edit/LocationEdit.vue'),
    meta: {
      resource: 'ServiceRequest',
      action: 'create',
      pageTitle: '',
      breadcrumb: [
        {
          text: 'location.my_locations',
          to: { name: 'apps-location-list' },
        },
        {
          text: 'Add',
          active: true,
        },
      ],
    },
  },
  {
    path: '/location/edit/:id',
    name: 'apps-location-edit',
    component: () => import('@/views/apps/location/edit/LocationEdit.vue'),
    meta: {
      resource: 'ServiceRequest',
      action: 'update',
      pageTitle: 'location.location',
      breadcrumb: [
        {
          text: 'location.my_locations',
          to: { name: 'apps-location-list' },
        },
        {
          text: 'Editing',
          active: true,
        },
      ],
    },
  },
  {
    path: '/locations',
    name: 'apps-location-list',
    component: () => import('@/views/apps/location/list/LocationList.vue'),
    meta: {
      resource: 'ServiceRequest',
      action: 'create',
      pageTitle: 'location.locations',
      contentClass: 'ecommerce-application',
      breadcrumb: [
        {
          text: 'List',
          active: true,
        },
      ],
    },
  },
  {
    path: '/location/set',
    name: 'apps-location-set',
    component: () => import('@/views/apps/location/SetLocation.vue'),
    meta: {
      resource: 'ServiceRequest',
      action: 'create',
      pageTitle: 'location.location',
      contentClass: 'ecommerce-application',
    },
  },

  // *===============================================---*
  // *--------- REQUEST SERVICE --------------------------*
  // *===============================================---*
  {
    path: '/service/search-requests',
    name: 'apps-service-search-requests',
    component: () => import('@/views/apps/service/request/list/SearchRequestList.vue'),
    meta: {
      resource: 'ServiceRequest',
      action: 'search',
      // contentRenderer: 'sidebar-left-detached',
      contentClass: 'ecommerce-application',
      pageTitle: 'Search Requests',
      breadcrumb: [
        {
          text: 'List',
          active: true,
        },
      ],
    },
  },
  {
    path: '/service/providers',
    name: 'apps-user-service-providers-list',
    component: () => import('@/views/apps/user/service-providers-list/ProvidersList.vue'),
    meta: {
      resource: 'ServiceRequest',
      action: 'manage',
      pageTitle: 'Service Providers',
      contentRenderer: 'sidebar-left-detached',
      contentClass: 'ecommerce-application',
      breadcrumb: [
        {
          text: 'List',
          active: true,
        },
      ],
    },
  },
  {
    path: '/service/providers/search',
    name: 'apps-service-providers-search',
    component: () => import('@/views/apps/user/service-providers-list/ProvidersList.vue'),
    meta: {
      resource: 'Referral',
      action: 'update',
      pageTitle: 'Service Providers',
      contentRenderer: 'sidebar-left-detached',
      contentClass: 'ecommerce-application',
      breadcrumb: [
        {
          text: 'List',
          active: true,
        },
      ],
    },
  },
  {
    path: '/service/my-requests',
    name: 'apps-service-my-requests',
    component: () => import('@/views/apps/service/request/list/RequestList.vue'),
    meta: {
      resource: 'ServiceRequest',
      action: 'read',
      contentRenderer: 'sidebar-left-detached',
      contentClass: 'ecommerce-application',
      pageTitle: 'My Requests',
      breadcrumb: [
        {
          text: 'List',
          active: true,
        },
      ],
    },
  },
  // {
  //   path: '/service/view/:id',
  //   name: 'apps-service-view',
  //   component: () => import('@/views/apps/point/service-view/ServiceView.vue'),
  // },
  {
    path: '/service/request/edit/:id',
    name: 'apps-service-request-edit',
    component: () => import('@/views/apps/service/request/edit/RequestEdit.vue'),
    meta: {
      resource: 'ServiceRequest',
      action: 'update',
      pageTitle: 'My Request',
      breadcrumb: [
        {
          text: 'My Requests',
          to: { name: 'apps-service-my-requests' },
        },
        {
          text: 'Editing',
          active: true,
        },
      ],
    },
  },
  // {
  //   path: '/service/request/add',
  //   name: 'apps-service-request-add',
  //   component: () => import('@/views/apps/service/request/edit/RequestEdit.vue'),
  //   meta: {
  //     resource: 'ServiceRequest',
  //     action: 'create',
  //     pageTitle: 'Add',
  //     breadcrumb: [
  //       {
  //         text: 'My Requests',
  //         to: { name: 'apps-service-my-requests' },
  //       },
  //       {
  //         text: 'New Request',
  //         active: true,
  //       },
  //     ],
  //   },
  // },
  {
    path: '/service/request/add-wizard',
    name: 'apps-service-request-add-wizard',
    component: () => import('@/views/apps/service/request/edit/RequestEditWizard.vue'),
    meta: {
      resource: 'Auth',
      action: 'read',
      pageTitle: 'Add',
      breadcrumb: [
        {
          text: 'My Requests',
          to: { name: 'apps-service-my-requests' },
        },
        {
          text: 'New Request',
          active: true,
        },
      ],
    },
  },
  {
    path: '/service/request/:id',
    name: 'apps-service-request-info',
    component: () => import('@/views/apps/service/request/RequestInfo.vue'),
    meta: {
      resource: 'ServiceRequest',
      action: 'read',
      pageTitle: 'My Request',
      contentClass: 'ecommerce-application',
      breadcrumb: [
        {
          text: 'My Requests',
          to: { name: 'apps-service-my-requests' },
        },
        {
          text: 'Info',
          active: true,
        },
      ],
    },
  },
  {
    path: '/service/search-request/:id',
    name: 'apps-search-service-request-details',
    component: () => import('@/views/apps/service/request/SearchRequestInfo.vue'),
    meta: {
      resource: 'ServiceRequest',
      action: 'read',
      pageTitle: 'Service Request',
      contentClass: 'ecommerce-application',
      breadcrumb: [
        {
          text: 'Requests List',
          to: { name: 'apps-service-search-requests' },
        },
        {
          text: 'Info',
          active: true,
        },
      ],
    },
  },

  // *===============================================---*
  // *---------  EVENT CALENDAR & IT'S FILTERS--------*
  // *===============================================---*
  {
    path: '/event/calendar/',
    name: 'event-calendar',
    component: () => import('@/views/apps/event/calendar/Calendar.vue'),
    meta: {
      resource: 'ServiceRequest',
      action: 'read',
    },
  },
  {
    path: '/event/calendar/add/:id',
    name: 'event-calendar-add',
    component: () => import('@/views/apps/event/calendar/Calendar.vue'),
    meta: {
      resource: 'ServiceRequest',
      action: 'read',
    },
  },
  {
    path: '/event/calendar/edit/:id',
    name: 'event-calendar-edit',
    component: () => import('@/views/apps/event/calendar/Calendar.vue'),
    meta: {
      resource: 'ServiceRequest',
      action: 'read',
    },
  },
  // Invoice
  // {
  //   path: '/apps/invoice/list',
  //   name: 'apps-invoice-list',
  //   component: () => import('@/views/apps/invoice/invoice-list/InvoiceList.vue'),
  // },
  // {
  //   path: '/apps/invoice/preview/:id',
  //   name: 'apps-invoice-preview',
  //   component: () => import('@/views/apps/invoice/invoice-preview/InvoicePreview.vue'),
  // },
  // {
  //   path: '/apps/invoice/add/',
  //   name: 'apps-invoice-add',
  //   component: () => import('@/views/apps/invoice/invoice-add/InvoiceAdd.vue'),
  // },
  // {
  //   path: '/apps/invoice/edit/:id',
  //   name: 'apps-invoice-edit',
  //   component: () => import('@/views/apps/invoice/invoice-edit/InvoiceEdit.vue'),
  // },
  // *===============================================---*
  // *---------  NOTIFICATIONS--------*
  // *===============================================---*
  {
    path: '/notifications',
    name: 'apps-notification-list',
    component: () => import('@/views/apps/notification/NotificationList.vue'),
    meta: {
      resource: 'ServiceRequest',
      action: 'read',
      contentRenderer: 'sidebar-left-detached',
      contentClass: 'ecommerce-application',
      pageTitle: 'Notifications',
      breadcrumb: [
        {
          text: 'List',
          active: true,
        },
      ],
    },
  },
]
