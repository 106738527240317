import axios from '@axios'

export default {
  namespaced: true,
  state: {
    event:{
      data:{},
      organizer:{}
    },
    calendarOptions: [
      {
        color: 'secondary',
        label: 'Private',
      },
      {
        color: 'info',
        label: 'Service',
      },

    ],
    selectedCalendars: ['Private', 'Service'],
  },
  getters: {
    event:state=>state.event,
    eventData: state=>state.event.data,
    eventOrganizer: state=>state.event.organizer

  },
  mutations: {
    SET_SELECTED_EVENTS(state, val) {
      state.selectedCalendars = val
    },

    setData(state, data) {
      state.event.data = data
    },

    setOrganizer(state, organizer) {
      state.event.organizer = organizer
    },

    DELETE_CALENDAR_DATA(state) {
      state.event = {
        data: {},
        organizer: {}
      },
      state.calendarOptions = [
          {
            color: 'secondary',
            label: 'Private',
          },
          {
            color: 'info',
            label: 'Service',
          },

        ],
      state.selectedCalendars = ['Private', 'Service']
    }

  },
  actions: {
    fetchEvent(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/events/${data.eventId}`, {
            params: data.queryParams,
          }).then(response => {
          resolve(response.data)
        })
          .catch(error => reject(error))
      })
    },
    fetchEvents(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/events', {
            params: queryParams,
          })
          .then(response => {
            resolve({ data: response.data.data.filter(event => queryParams.calendars.includes(event.extendedProps.calendar)) })
          })
          .catch(error => reject(error))
      })
    },
    addEvent(ctx, { data }) {
      return new Promise((resolve, reject) => {
        axios
          .post('/events', { data })
          .then(response => {
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    updateEvent(ctx, { data }) {
      return new Promise((resolve, reject) => {
        axios
          .patch(`/events/${data.id}`, { data })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    removeEvent(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/events/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
